<template>
    <Modal close-esc-event="keydown" :close-button="false" modal-class="w-128 m-auto border-0" mask-class="grid" @closing="onModalClose">
        <template #toggle="scope">
            <slot v-bind="scope" />
        </template>

        <template #header="scope">
            <CloseModalButton @close="scope.close"></CloseModalButton>
        </template>

        <template #body="scope">
            <form class="bg-white relative rounded-lg grid py-6 space-y-2 px-10 m-auto">
                <MultiStepFormHeaderIcon v-if="header || form.event" :icon-source="header?.icon_url || form.event?.icon_url || ''" :is-final-step="isFinalStep"></MultiStepFormHeaderIcon>
                <transition name="fade" mode="out-in">
                    <component
                        :is="currentStep.component"
                        v-model:state="form"
                        v-model:assignment="computedAssignment"
                        :header="header"
                        :config="currentStepConfig"
                        @submit="next"
                        @go-to-step="goToStep($event)"
                        @skip="goToStep(currentStepIndex + 2)"
                        @back="previous"
                        @close="scope.close"
                        @force-close="onForceClose(scope.close)"
                        @submission="$emit('submission')"
                    ></component>
                </transition>
            </form>
        </template>
    </Modal>
</template>

<script lang="ts">
import { defineComponent, Ref, computed, watch, ref } from 'vue';
import { Modal } from '@makeabledk/vue-ui/components/modal';
import { Form } from '@makeabledk/vue-ui/support/http';
import isEqual from 'lodash-es/isEqual';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';
import { Assignment, Form as FormType, MultiStepFormState } from '@/types';
import CustomButton from '@/components/ui/CustomButton.vue';
import DynamicForm from '@/components/common/multiStepFormSteps/DynamicForm.vue';
import LocationForm from '@/components/common/actionModal/LocationForm.vue';
import ConfirmationForm from '@/components/common/multiStepFormSteps/ConfirmationForm.vue';
import SubmissionStatusForm from '@/components/common/multiStepFormSteps/SubmissionStatusForm.vue';
import useMultiSteps from '@/components/common/composables/useMultiSteps';
import useResettableForm from '@/components/common/composables/useResettableForm';
import MultiStepFormHeaderIcon from '@/components/common/MultiStepFormHeaderIcon.vue';
import { i18n } from '@/plugins/internationalization/i18n';
import CloseModalButton from '@/components/common/CloseModalButton.vue';
import { MutationType } from '@/plugins/store/mutations';

export default defineComponent({
    components: { Modal, CustomButton, MultiStepFormHeaderIcon, CloseModalButton },
    emits: ['closing', 'submission', 'update:assignment'],
    props: {
        stepConfig: {
            type: Array,
            default: [
                {},
                {},
                {
                    rejectable: false,
                    submitActionType: ActionType.PutForm,
                },
                {},
            ],
        },
        initialState: {
            type: Object as () => MultiStepFormState,
            default: {
                location: null,
                form: null,
                confirmed: false,
                farm: null,
                record_id: null,
                notification: null,
                event: null,
            },
        },
        initialStepIndex: {
            type: Number,
            default: 0,
        },
        header: {
            type: Object as () => null | { icon_url: string; text: string },
            default: null,
        },
        assignment: {
            type: Object as () => null | Assignment,
            default: null,
        },
    },
    setup(props, context) {
        const resettableForm = useResettableForm().setInitial(props.initialState);
        const form = resettableForm.getInstance() as Ref<Form<MultiStepFormState> & MultiStepFormState>;

        watch(
            () => props.initialState,
            (newValue) => {
                if (newValue) {
                    resettableForm.setInitial(props.initialState);
                }
            }
        );

        const multiSteps = useMultiSteps([{ component: LocationForm }, { component: DynamicForm }, { component: ConfirmationForm }, { component: SubmissionStatusForm }]).setInitial(props.initialStepIndex);
        const currentStepConfig = computed(() => props.stepConfig[multiSteps.currentStepIndex.value] || {});

        const handleNotificationFormClose = async () => {
            if (!form.value.notification) {
                return;
            }
            store.commit(MutationType.SetPendingChangesToNotifications, true);
            await store.dispatch(ActionType.EditNotification, { form: { notification: form.value.notification, update: [{ value: true, path: '/read', op: 'replace' }] } });
            await Promise.all([
                store.dispatch(ActionType.GetNotifications, { options: { ignoreCache: true } }),
                store.dispatch(ActionType.GetMeta, { options: { ignoreCache: true, skipLoadingIndicator: true } }),
            ]);
            store.commit(MutationType.SetPendingChangesToNotifications, false);
        };

        const onModalClose = (next: (closeModal: boolean) => boolean) => {
            if (isForceClosing.value) {
                isForceClosing.value = false;
            } else if ([1, 2].includes(multiSteps.currentStepIndex.value) && !isEqual(resettableForm.getInitial().form, form.value.form)) {
                if (!window.confirm(i18n.global.t('global.forms.confirmCloseModalMessage'))) {
                    return next(false);
                }
            }

            handleNotificationFormClose();
            context.emit('closing', multiSteps.isFinalStep);
            multiSteps.reset();
            resettableForm.reset();
            return true;
        };

        const isForceClosing = ref(false);
        const onForceClose = (close: CallableFunction) => {
            isForceClosing.value = true;
            close();
        };

        const computedAssignment = computed({
            get() {
                return props.assignment;
            },
            set(value) {
                context.emit('update:assignment', value);
            },
        });

        return { form, ...multiSteps, onModalClose, currentStepConfig, computedAssignment, onForceClose };
    },
});
</script>
