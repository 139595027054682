<template>
    <DashboardContentLayout heading="" :has-period-picker="false" :has-pig-type-picker="false">
        <div class="space-y-8">
            <KpiGrid></KpiGrid>
            <DashboardContentHeader :heading="$t('views.overview.header.heading')" :has-period-picker="true" :has-pig-type-picker="true"></DashboardContentHeader>
            <GraphGrid></GraphGrid>
        </div>
    </DashboardContentLayout>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import DashboardContentLayout from '@/components/layouts/dashboardLayout/content/DashboardContentLayout.vue';
import KpiGrid from '@/views/dashboard/overview/KpiGrid.vue';
import GraphGrid from '@/views/dashboard/overview/GraphGrid.vue';
import DashboardContentHeader from '@/components/layouts/dashboardLayout/content/DashboardContentHeader.vue';

export default defineComponent({
    components: { DashboardContentLayout, DashboardContentHeader, KpiGrid, GraphGrid },
});
</script>
