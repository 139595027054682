import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = { class: "flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("a", {
    class: _normalizeClass([{ 'pointer-events-none text-opacity-50': _ctx.disabled }, "bg-primary-700 text-primary-800 bg-opacity-20 rounded-lg py-2 px-3 inline-flex space-x-2"]),
    target: "_blank",
    href: _ctx.element.value
  }, [
    _createElementVNode("span", null, _toDisplayString(_ctx.element.label), 1),
    _createElementVNode("span", _hoisted_2, [
      _createVNode(_component_Icon, {
        class: _normalizeClass([{ 'opacity-50': _ctx.disabled }, "-rotate-90 transform my-auto"]),
        src: _ctx.IconSource.DropdownPrimary
      }, null, 8, ["class", "src"])
    ])
  ], 10, _hoisted_1))
}