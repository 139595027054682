import { createApp } from 'vue';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import Vue3Lottie from 'vue3-lottie';
import Authentication from '@/plugins/authentication';
import ToastOptions from '@/plugins/toastification';
import router from '@/plugins/router';
import App from '@/App.vue';
import Icon from '@/components/icons/Icon.vue';
import { i18n } from '@/plugins/internationalization/i18n';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';
import '@/plugins/highcharts';
import '@/plugins/nprogress';
import 'vue3-lottie/dist/style.css';
import { IconSource } from '@/types';
import '@/assets/css/main.css';

Authentication.initAuth();

const app = createApp(App);

app.use(router).use(i18n).use(store).component('Icon', Icon).use(Vue3Lottie, { name: 'Vue3Lottie' }).use(Toast, ToastOptions).mount('#app');

app.config.globalProperties.IconSource = IconSource;

store.dispatch(ActionType.InitDashboard);
