import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "fixed w-full",
  style: {"z-index":"1030"}
}
const _hoisted_2 = { class: "bg-primary-800 h-24" }
const _hoisted_3 = { class: "mx-auto max-w-dashboard" }
const _hoisted_4 = {
  style: {"height":"100px"},
  class: "flex items-center h-24"
}
const _hoisted_5 = { class: "flex items-center h-full" }
const _hoisted_6 = { class: "flex-shrink-0 h-full" }
const _hoisted_7 = { class: "h-full w-full" }
const _hoisted_8 = { class: "flex justify-end items-center divide-x divide-primary-700 h-full" }
const _hoisted_9 = { class: "px-4 xl:px-6 h-full grid content-center" }
const _hoisted_10 = { class: "px-4 xl:px-6 h-full grid content-center" }
const _hoisted_11 = { class: "px-4 xl:px-6 h-full grid content-center" }
const _hoisted_12 = {
  key: 0,
  class: "px-4 xl:px-6 h-full grid content-center"
}
const _hoisted_13 = { class: "pl-4 xl:pl-6 pr-6 h-full flex space-x-4 items-center content-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LocationPicker = _resolveComponent("LocationPicker")!
  const _component_Settings = _resolveComponent("Settings")!
  const _component_LanguagePicker = _resolveComponent("LanguagePicker")!
  const _component_Notifications = _resolveComponent("Notifications")!
  const _component_Assignments = _resolveComponent("Assignments")!
  const _component_UpdateKeyFigures = _resolveComponent("UpdateKeyFigures")!
  const _component_CreateBatchAction = _resolveComponent("CreateBatchAction")!
  const _component_CreateEventAction = _resolveComponent("CreateEventAction")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("nav", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_LocationPicker)
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_Settings)
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_LanguagePicker)
              ]),
              _createElementVNode("div", _hoisted_11, [
                _createVNode(_component_Notifications)
              ]),
              (_ctx.hasAssignmentsMobuleEnabled)
                ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                    _createVNode(_component_Assignments)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("div", null, [
                  _createVNode(_component_UpdateKeyFigures)
                ]),
                _createElementVNode("div", null, [
                  _createVNode(_component_CreateBatchAction, { class: "my-auto" })
                ]),
                _createElementVNode("div", null, [
                  _createVNode(_component_CreateEventAction, { class: "my-auto" })
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}