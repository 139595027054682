import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8c31d066"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dashboard-content-header w-full" }
const _hoisted_2 = { class: "flex-grow text-center text-left font-semibold text-3xl pb-0 whitespace-nowrap" }
const _hoisted_3 = { class: "flex space-x-4" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PigTypePicker = _resolveComponent("PigTypePicker")!
  const _component_PeriodPicker = _resolveComponent("PeriodPicker")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.heading), 1),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.hasPigTypePicker)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_PigTypePicker)
          ]))
        : _createCommentVNode("", true),
      (_ctx.hasPeriodPicker)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_PeriodPicker)
          ]))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "top-right", {}, undefined, true)
    ])
  ]))
}