<template>
    <div class="space-y-6">
        <h2 class="text-xl font-semibold text-center">{{ $t('views.assignments.tabs.assignments.modals.createAssignment.header') }}</h2>
        <form class="flex flex-col h-full min-h-44">
            <CustomMultiSelect
                v-model:value="computedState.locations"
                class="self-start w-full"
                :items="farms"
                return-object
                required
                item-value="_id"
                item-text="name"
                allow-empty
                dropdown-height-class="max-h-84"
                :rules="[{ validate: (value) => value !== null, message: '' }]"
                :label="$t('global.forms.steps.location.selectLocationFieldLabel')"
                :disabled="false"
                show-required-indication
                :show-hint="false"
                show-select-all
                groups
                group-key="locations"
                group-item-value="barn_id"
            ></CustomMultiSelect>
            <ExpandCollapseTransition>
                <div v-if="activeLocationSections">
                    <CustomMultiSelect
                        v-model:value="selectedSections"
                        class="self-start w-full"
                        :items="activeLocationSections"
                        return-object
                        item-value="id"
                        item-text="name"
                        dropdown-height-class="max-h-84"
                        :rules="[{ validate: (value) => value !== null, message: '' }]"
                        :label="$t('global.forms.steps.location.selectSectionFieldLabel')"
                        :disabled="false"
                        :show-hint="false"
                        show-select-all
                    ></CustomMultiSelect>
                </div>
            </ExpandCollapseTransition>
            <CustomButton :disabled="!validForm" @click="onSubmit">
                {{ $t('global.forms.steps.dynamicForm.nextButtonLabel') }}
            </CustomButton>
        </form>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';
import CustomMultiSelect from '@/components/ui/CustomMultiSelect.vue';
import CustomButton from '@/components/ui/CustomButton.vue';
import { Farm, Barn, Batch, BatchEvent, Section, Action, Module } from '@/types';
import ExpandCollapseTransition from '@/components/common/ExpandCollapseTransition.vue';

interface Form {
    locations: any[] | null;
}

export default defineComponent({
    components: { CustomMultiSelect, CustomButton, ExpandCollapseTransition },
    props: {
        state: {
            type: Object as () => Form,
            required: true,
        },
    },
    setup(props, { emit }) {
        store.dispatch(ActionType.GetFarms);
        store.dispatch(ActionType.GetBarns);
        store.dispatch(ActionType.GetSections);

        const ACTION_ID = 31;

        const hasSectionsModuleEnabled = computed(() => store.getters.hasModuleEnabled(Module.Sections));

        const computedState = computed<Form>({
            get() {
                return props.state;
            },
            set(state) {
                emit('update:state', state);
            },
        });
        const selectedSections = ref<Section[] | null>([]);

        const hasLocationSelected = computed(() => Boolean(computedState.value.locations?.length && computedState.value.locations?.[0]?.barn_id !== undefined));
        const hasFarmSelected = computed(() => Boolean(computedState.value.locations?.length && computedState.value.locations?.[0]?._id !== undefined));

        function siteWorksForAction(site: Farm | Barn | Batch) {
            return site.accessible_actions.find((a) => a === ACTION_ID) || (hasSectionsModuleEnabled.value && (site as Barn).sections?.some((s: Section) => s.accessible_actions.includes(ACTION_ID)));
        }

        const activeAction = computed<Action | undefined>(() => store.state.actions.find((currentAction) => currentAction.id === ACTION_ID));

        const farms = computed<Farm[] & { _id?: number; disabled?: boolean }>(() => {
            if (!activeAction.value) {
                return [];
            }

            const farms: Farm[] = store.getters.getLocations(true, true, false);

            farms.forEach((farm: Farm & { _id?: number; disabled?: boolean }) => {
                const hasLocationsDisabled =
                    Array.isArray(computedState.value?.locations) && computedState.value.locations.some((location) => location.farm_id !== farm.farm_id || hasFarmSelected.value) === true;

                farm.locations = (farm.locations as (Barn & { disabled?: boolean })[])?.flatMap((barn: Barn): (Barn & { disabled?: boolean })[] =>
                    siteWorksForAction(barn) ? [{ ...barn, disabled: hasLocationsDisabled }] : []
                );

                farm._id = farm.farm_id;
                farm.disabled = hasLocationSelected.value || !siteWorksForAction(farm) || farm.permission.key !== 'ReadWrite';
            });

            return farms.filter((currentFarm) => (currentFarm.locations?.length || siteWorksForAction(currentFarm)) && currentFarm.permission.key === 'ReadWrite');
        });

        const activeLocationSections = computed(() => {
            if (!computedState.value.locations?.length || computedState.value.locations?.length > 1) {
                return null;
            }

            return (computedState.value.locations?.[0] as Barn)?.sections || null;
        });

        watch(
            () => computedState.value.locations,
            (newValue, oldValue) => {
                if (newValue === null || newValue.length === 0 || newValue.length > 1 || newValue[0].barn_id === undefined || newValue[0].barn_id !== oldValue?.[0]?.barn_id) {
                    selectedSections.value = [];
                }
            }
        );

        const validForm = computed(() => computedState.value.locations?.some((location) => siteWorksForAction(location) || selectedSections.value?.length));

        async function onSubmit() {
            if (!validForm.value) {
                return;
            }

            const selectedSectionIds = selectedSections.value?.map((section) => section.id) || [];
            const locationIds = computedState.value.locations?.map((location) => location.barn_id || location._id) || [];

            const form = await store.dispatch(ActionType.GetForm, {
                form: {
                    form_id: 31,
                    location_ids: selectedSectionIds.length ? selectedSectionIds.join(',') : locationIds.join(','),
                },
            });

            if (form) {
                Object.assign(computedState.value, { form });

                emit('submit');
            }
        }

        return {
            farms,
            computedState,
            validForm,
            onSubmit,
            selectedSections,
            activeLocationSections,
        };
    },
});
</script>
