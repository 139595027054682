import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "space-y-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KpiGrid = _resolveComponent("KpiGrid")!
  const _component_DashboardContentHeader = _resolveComponent("DashboardContentHeader")!
  const _component_GraphGrid = _resolveComponent("GraphGrid")!
  const _component_DashboardContentLayout = _resolveComponent("DashboardContentLayout")!

  return (_openBlock(), _createBlock(_component_DashboardContentLayout, {
    heading: "",
    "has-period-picker": false,
    "has-pig-type-picker": false
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_KpiGrid),
        _createVNode(_component_DashboardContentHeader, {
          heading: _ctx.$t('views.overview.header.heading'),
          "has-period-picker": true,
          "has-pig-type-picker": true
        }, null, 8, ["heading"]),
        _createVNode(_component_GraphGrid)
      ])
    ]),
    _: 1
  }))
}