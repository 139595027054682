<template>
    <header class="dashboard-content-header w-full">
        <h1 class="flex-grow text-center text-left font-semibold text-3xl pb-0 whitespace-nowrap">{{ heading }}</h1>
        <div class="flex space-x-4">
            <div v-if="hasPigTypePicker">
                <PigTypePicker></PigTypePicker>
            </div>
            <div v-if="hasPeriodPicker">
                <PeriodPicker></PeriodPicker>
            </div>
            <slot name="top-right"></slot>
        </div>
    </header>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import PeriodPicker from '@/components/ui/PeriodPicker.vue';
import PigTypePicker from '@/components/ui/PigTypePicker.vue';

export default defineComponent({
    components: { PeriodPicker, PigTypePicker },
    props: {
        heading: {
            type: String,
            default: '',
        },
        hasPigTypePicker: {
            type: Boolean,
            default: false,
        },
        hasPeriodPicker: {
            type: Boolean,
            default: false,
        },
    },
});
</script>

<style scoped>
.dashboard-content-header > div {
    @apply mt-2;
}
@media (min-width: 1152px) {
    .dashboard-content-header {
        display: flex;
    }
    .dashboard-content-header > div {
        @apply mt-0;
    }
}
</style>
