import { MutationTree } from 'vuex';
import * as Highcharts from 'highcharts';
import { store } from '@/plugins/store';

import { i18n } from '@/plugins/internationalization/i18n';
import {
    Action,
    Farm,
    Barn,
    Batch,
    Form,
    Event,
    BatchEvent,
    SettingEvent,
    User,
    KpiType,
    KpiPreference,
    Kpi,
    Notification,
    NotificationResponse,
    Record,
    RecordResponse,
    RecordType,
    RawMaterial,
    Mix,
    MixType,
    Directory,
    RawMaterialRegisterItem,
    Meta,
    MixRegisterItem,
    DirectoryFarm,
    UserRole,
    DirectoryRole,
    DirectoryPermission,
    DirectoryUser,
    DirectoryBarn,
    Section,
    EconomyPostingsResponse,
    Language,
    DirectoryBatch,
    EconomyPostingCategory,
    EconomyStatusEntry,
    EconomyListing,
    EconomyPostPayment,
    EconomyBudgetEntry,
    EconomyEvent,
    EconomyDeadPayment,
    ConfigEvent,
    PigType,
    DirectorySection,
    AssignmentResponse,
    AssignmentType,
    Module,
    HealthDrugs,
    TreatmentProcedure,
    Treatment,
    TreatmentHistory,
    MiscEvent,
    AssignmentTemplate,
} from '@/types';
import { State } from '../state';
import { ActionType, ActionAugments } from '../actions';

export enum MutationType {
    SetDashboardLoaded = 'SET_DASHBOARD_LOADED',
    SetIcons = 'SET_ICONS',
    SetFarms = 'SET_FARMS',
    SetDirectoryFarms = 'SET_DIRECTORY_FARMS',
    SetDirectoryBarns = 'SET_DIRECTORY_BARNS',
    SetDirectorySections = 'Set_DIRECTORY_SECTIONS',
    SetSections = 'SET_DIRECTORY_SECTIONS',
    SetDirectoryRoles = 'SET_DIRECTORY_ROLES',
    SetDirectoryPermissions = 'SET_DIRECTORY_PERMISSIONS',
    SetDirectoryUsers = 'SET_DIRECTORY_USERS',
    SetBarns = 'SET_BARNS',
    SetBatches = 'SET_BATCHES',
    SetEvents = 'SET_EVENTS',
    SetBatchEvents = 'SET_BATCH_EVENTS',
    SetSettingEvents = 'SET_SETTING_EVENTS',
    SetEconomyEvents = 'SET_ECONOMY_EVENTS',
    SetConfigEvents = 'SET_CONFIG_EVENTS',
    SetMiscEvents = 'SET_MISC_EVENTS',
    SetActions = 'SET_ACTIONS',
    SetNotificationResponse = 'SET_NOTIFICATION_RESPONSE',
    SetAssignmentResponse = 'SET_ASSIGNMENT_RESPONSE',
    SetAssignmentTypes = 'SET_ASSIGNMENT_TYPES',
    SetKpiTypes = 'SET_KPI_TYPES',
    SetKpiPreferences = 'SET_KPI_PREFERENCES',
    SetKpis = 'SET_KPIS',
    SetUser = 'SET_USER',
    SetUserRole = 'SET_USER_ROLE',
    SetActiveDirectory = 'SET_ACTIVE_DIRECTORY',
    SetLanguages = 'SET_LANGUAGES',
    SetAuthToken = 'SET_AUTH_TOKEN',
    AddInProgressAction = 'ADD_IN_PROGRESS_ACTION',
    RemoveInProgressAction = 'REMOVE_IN_PROGRESS_ACTION',
    SetPeriodPickerDateString = 'SET_PERIOD_PICKER_DATE_STRING',
    SetRecordResponse = 'SET_RECORD_RESPONSE',
    SetRecordTypes = 'SET_RECORD_TYPES',
    SetEconomyPostingsResponse = 'SET_ECONOMY_POSTINGS_RESPONSE',
    SetEconomyPostingCategories = 'SET_ECONOMY_POSTING_CATEGORIES',
    SetEconomyStatusEntries = 'SET_ECONOMY_STATUS_ENTRIES',
    SetEconomyListings = 'SET_ECONOMY_LISTINGS',
    SetEconomyPostPayments = 'SET_ECONOMY_POST_PAYMENTS',
    SetEconomyBudgetEntries = 'SET_ECONOMY_BUDGET_ENTRIES',
    SetEconomyDeadPayments = 'SET_ECONOMY_DEAD_PAYMENTS',
    SetHealthDrugs = 'SET_HEALTH_DRUGS',
    SetRawMaterials = 'SET_RAW_MATERIALS',
    SetRawMaterialRegisterItems = 'SET_RAW_MATERIAL_REGISTER_ITEMS',
    SetMixes = 'SET_MIXES',
    SetMixTypes = 'SET_MIX_TYPES',
    SetMixRegisterItems = 'SET_MIX_REGISTER_ITEMS',
    SetDirectories = 'SET_DIRECTORIES',
    SetMeta = 'SET_META',
    SetFooter = 'SET_FOOTER',
    SetPigTypePickerOptions = 'SET_PIG_TYPE_PICKER_OPTIONS',
    SetPigType = 'SET_PIG_TYPE',
    SetSelectedFarmIds = 'SET_SELECTED_FARM_IDS',
    SetSelectedBarnIds = 'SET_SELECTED_BARN_IDS',
    SetSelectedBatchIds = 'SET_SELECTED_BATCH_IDS',
    SetSelectedSectionIds = 'SET_SELECTED_SECTION_IDS',
    InitPeriodPicker = 'INIT_PERIOD_PICKER',
    SetPendingChangesToNotifications = 'SET_PENDING_CHANGES_TO_NOTIFICATIONS',
    SetFormRecentlySubmitted = 'SET_FORM_REECNTLY_SUBMITTED',
    SetTreatmentProcedures = 'SET_TREATMENT_PROCEDURES',
    SetTreatments = 'SET_TREATMENTS',
    SetTreatmentHistory = 'SET_TREATMENT_HISTORY',
    SetAssignmentTemplates = 'SET_ASSIGNMENT_TEMPLATES',
}

export type Mutations = {
    [MutationType.SetDashboardLoaded](state: State, loaded: boolean): void;
    [MutationType.SetIcons](state: State, icons: { [key: string]: string }): void;
    [MutationType.SetFarms](state: State, farms: Farm[]): void;
    [MutationType.SetDirectoryFarms](state: State, directoryFarms: DirectoryFarm[]): void;
    [MutationType.SetDirectoryBarns](state: State, directoryBarns: DirectoryBarn[]): void;
    [MutationType.SetDirectorySections](state: State, directorySections: DirectorySection[]): void;
    [MutationType.SetSections](state: State, sections: Section[]): void;
    [MutationType.SetDirectoryRoles](state: State, directoryRoles: DirectoryRole[]): void;
    [MutationType.SetDirectoryPermissions](state: State, directoryPermissions: DirectoryPermission[]): void;
    [MutationType.SetDirectoryUsers](state: State, users: DirectoryUser[]): void;
    [MutationType.SetBarns](state: State, barns: Barn[]): void;
    [MutationType.SetBatches](state: State, barns: DirectoryBatch[]): void;
    [MutationType.SetEvents](state: State, events: Event[]): void;
    [MutationType.SetBatchEvents](state: State, events: BatchEvent[]): void;
    [MutationType.SetSettingEvents](state: State, events: SettingEvent[]): void;
    [MutationType.SetEconomyEvents](state: State, events: EconomyEvent[]): void;
    [MutationType.SetConfigEvents](state: State, events: ConfigEvent[]): void;
    [MutationType.SetMiscEvents](state: State, events: MiscEvent[]): void;
    [MutationType.SetActions](state: State, actions: Action[]): void;
    [MutationType.SetNotificationResponse](state: State, notificationResponse: NotificationResponse): void;
    [MutationType.SetAssignmentResponse](state: State, assignmentResponse: AssignmentResponse): void;
    [MutationType.SetAssignmentTypes](state: State, assignmentTypes: AssignmentType[]): void;
    [MutationType.SetKpiTypes](state: State, kpiTypes: KpiType[]): void;
    [MutationType.SetKpiPreferences](state: State, kpiPreferences: KpiPreference[]): void;
    [MutationType.SetKpis](state: State, kpis: Kpi[]): void;
    [MutationType.SetRecordResponse](state: State, recordResponse: RecordResponse): void;
    [MutationType.SetRecordTypes](state: State, recordTypes: RecordType[]): void;
    [MutationType.SetEconomyPostingsResponse](state: State, economyPostingsResponse: EconomyPostingsResponse): void;
    [MutationType.SetEconomyPostingCategories](state: State, economyPostingCategories: EconomyPostingCategory[]): void;
    [MutationType.SetEconomyStatusEntries](state: State, economyStatusEntries: EconomyStatusEntry[]): void;
    [MutationType.SetEconomyListings](state: State, economyListings: EconomyListing[]): void;
    [MutationType.SetEconomyPostPayments](state: State, economyPostPayments: EconomyPostPayment[]): void;
    [MutationType.SetEconomyListings](state: State, economyStatusEntries: EconomyListing[]): void;
    [MutationType.SetEconomyBudgetEntries](state: State, economyBudgetEntries: EconomyBudgetEntry[]): void;
    [MutationType.SetEconomyDeadPayments](state: State, economyDeadPayments: EconomyDeadPayment[]): void;
    [MutationType.SetHealthDrugs](state: State, healthDrugs: HealthDrugs[]): void;
    [MutationType.SetRawMaterials](state: State, rawMaterials: RawMaterial[]): void;
    [MutationType.SetRawMaterialRegisterItems](state: State, rawMaterialRegisterItems: RawMaterialRegisterItem[]): void;
    [MutationType.SetMixes](state: State, mixes: Mix[]): void;
    [MutationType.SetMixTypes](state: State, mixTypes: MixType[]): void;
    [MutationType.SetMixRegisterItems](state: State, mixTypes: MixRegisterItem[]): void;
    [MutationType.SetDirectories](state: State, directories: Directory[]): void;
    [MutationType.SetMeta](state: State, meta: Meta): void;
    [MutationType.SetFooter](state: State, footer: { footer_html: string }): void;
    [MutationType.SetUser](state: State, user: User): void;
    [MutationType.SetUserRole](state: State, userRole: UserRole): void;
    [MutationType.SetActiveDirectory](state: State, activeDirectory: string): void;
    [MutationType.SetLanguages](state: State, languages: Language[]): void;
    [MutationType.SetAuthToken](state: State, token: string): void;
    [MutationType.SetPigTypePickerOptions](state: State, pigTypePickerOptions: { weanersWidth: number; slaughterPigsWidth: number }): void;
    [MutationType.SetPigType](state: State, pigType: PigType): void;
    [MutationType.SetSelectedFarmIds](state: State, farmIds: number[]): void;
    [MutationType.SetSelectedBarnIds](state: State, barnIds: number[]): void;
    [MutationType.SetSelectedBatchIds](state: State, batchIds: number[]): void;
    [MutationType.SetSelectedSectionIds](state: State, sectionIds: number[]): void;
    [MutationType.AddInProgressAction](state: State, action: ActionType): void;
    [MutationType.RemoveInProgressAction](state: State, action: ActionType): void;
    [MutationType.SetPeriodPickerDateString](state: State, dateString: string): void;
    [MutationType.InitPeriodPicker](state: State): void;
    [MutationType.SetPendingChangesToNotifications](state: State, pendingChangesToNotifications: boolean): void;
    [MutationType.SetFormRecentlySubmitted](state: State, recentlySubmitted: boolean): void;
    [MutationType.SetTreatmentProcedures](state: State, treatmentProcedures: TreatmentProcedure[]): void;
    [MutationType.SetTreatments](state: State, treatments: Treatment[]): void;
    [MutationType.SetTreatmentHistory](state: State, treatmentHistory: TreatmentHistory): void;
    [MutationType.SetAssignmentTemplates](state: State, assignmentTemplates: AssignmentTemplate[]): void;
};

export const mutations: MutationTree<State> & Mutations = {
    [MutationType.SetDashboardLoaded](state, loaded) {
        state.dashboardLoaded = loaded;
    },

    [MutationType.SetIcons](state, icons) {
        state.icons = icons;
    },

    [MutationType.SetEvents](state, events) {
        state.events = events;
    },

    [MutationType.SetBatchEvents](state, events) {
        state.batchEvents = events;
    },

    [MutationType.SetSettingEvents](state, events) {
        state.settingEvents = events;
    },

    [MutationType.SetEconomyEvents](state, events) {
        state.economyEvents = events;
    },

    [MutationType.SetConfigEvents](state, events) {
        state.configEvents = events;
    },

    [MutationType.SetMiscEvents](state, events) {
        state.miscEvents = events;
    },

    [MutationType.SetActions](state, actions) {
        state.actions = actions;
    },

    [MutationType.SetNotificationResponse](state, notificationResponse) {
        state.notificationResponse = notificationResponse;
    },

    [MutationType.SetAssignmentResponse](state, assignmentResponse) {
        state.assignmentResponse = assignmentResponse;
    },

    [MutationType.SetAssignmentTypes](state, assignmentTypes) {
        state.assignmentTypes = assignmentTypes;
    },

    [MutationType.SetRecordResponse](state, recordResponse) {
        state.recordResponse = recordResponse;
    },

    [MutationType.SetRecordTypes](state, recordTypes) {
        state.recordTypes = recordTypes;
    },

    [MutationType.SetEconomyPostingsResponse](state, economyPostingsResponse) {
        state.economyPostingsResponse = economyPostingsResponse;
    },

    [MutationType.SetEconomyPostingCategories](state, economyPostingCategories) {
        state.economyPostingCategories = economyPostingCategories;
    },

    [MutationType.SetEconomyStatusEntries](state, economyStatusEntries) {
        state.economyStatusEntries = economyStatusEntries;
    },

    [MutationType.SetEconomyListings](state, economyListings) {
        state.economyListings = economyListings;
    },

    [MutationType.SetEconomyPostPayments](state, economyPostPayments) {
        state.economyPostPayments = economyPostPayments;
    },

    [MutationType.SetEconomyBudgetEntries](state, economyBudgetEntries) {
        state.economyBudgetEntries = economyBudgetEntries;
    },

    [MutationType.SetEconomyDeadPayments](state, economyDeadPayments) {
        state.economyDeadPayments = economyDeadPayments;
    },

    [MutationType.SetHealthDrugs](state, healthDrugs) {
        state.healthDrugs = healthDrugs;
    },

    [MutationType.SetBarns](state, barns) {
        state.barns = barns;
        initSiteSelections(state);
    },

    [MutationType.SetBatches](state, batches) {
        state.batches = batches;
    },

    [MutationType.SetFarms](state, farms) {
        state.farms = farms;
        initSiteSelections(state);
    },

    [MutationType.SetDirectoryFarms](state, directoryFarms) {
        state.directoryFarms = directoryFarms;
    },

    [MutationType.SetDirectoryBarns](state, directoryBarns) {
        state.directoryBarns = directoryBarns;
    },

    [MutationType.SetDirectorySections](state, directorySections) {
        state.directorySections = directorySections;
    },

    [MutationType.SetSections](state, sections) {
        state.sections = sections;
        initSiteSelections(state);
    },

    [MutationType.SetDirectoryRoles](state, directoryRoles) {
        state.directoryRoles = directoryRoles;
    },

    [MutationType.SetDirectoryPermissions](state, directoryPermissions) {
        state.directoryPermissions = directoryPermissions;
    },

    [MutationType.SetDirectoryUsers](state, directoryUsers) {
        state.directoryUsers = directoryUsers;
    },

    [MutationType.SetKpiTypes](state, kpiTypes) {
        state.kpiTypes = kpiTypes;
    },

    [MutationType.SetKpiPreferences](state, kpiPreferences) {
        state.kpiPreferences = kpiPreferences;
    },

    [MutationType.SetKpis](state, kpis) {
        state.kpis = kpis;
    },

    [MutationType.SetRawMaterials](state, rawMaterials) {
        state.rawMaterials = rawMaterials;
    },

    [MutationType.SetRawMaterialRegisterItems](state, rawMaterialRegisterItems) {
        state.rawMaterialRegisterItems = rawMaterialRegisterItems;
    },

    [MutationType.SetMixes](state, mixes) {
        state.mixes = mixes;
    },

    [MutationType.SetMixTypes](state, mixTypes) {
        state.mixTypes = mixTypes;
    },

    [MutationType.SetMixRegisterItems](state, mixRegisterItems) {
        state.mixRegisterItems = mixRegisterItems;
    },

    [MutationType.SetDirectories](state, directories) {
        state.directories = directories;
    },

    [MutationType.SetPigTypePickerOptions](state, pigTypePickerOptions) {
        state.pigTypePickerOptions = pigTypePickerOptions;
    },

    [MutationType.SetPigType](state, pigType) {
        localStorage.setItem('pigtype', pigType);
        state.pigType = pigType;
        resetSelectedSites(state);
    },

    [MutationType.SetSelectedFarmIds](state, selectedFarmIds) {
        state.selectedFarmIds = selectedFarmIds;
    },

    [MutationType.SetSelectedBarnIds](state, barnIds) {
        state.selectedBarnIds = barnIds;
    },

    [MutationType.SetSelectedBatchIds](state, batchIds) {
        state.selectedBatchIds = batchIds;
    },

    [MutationType.SetSelectedSectionIds](state, sectionIds) {
        state.selectedSectionIds = sectionIds;
    },

    [MutationType.SetMeta](state, meta) {
        state.meta = meta;
    },

    [MutationType.SetFooter](state, footer) {
        state.footer = footer;
    },

    [MutationType.SetUser](state, user) {
        i18n.global.locale = user.language_code;
        document.documentElement.setAttribute('lang', user.language_code); // Firefox uses this attribute to decide comma or point in decimal inputs
        Highcharts.setOptions({ lang: { decimalPoint: i18n.global.locale === 'en' ? '.' : ',' } });
        state.user = user;
    },

    [MutationType.SetUserRole](state, userRole) {
        state.userRole = userRole;
    },

    [MutationType.SetActiveDirectory](state, activeDirectory: string) {
        state.activeDirectory = activeDirectory;
    },

    [MutationType.SetLanguages](state, languages) {
        state.languages = languages;
    },

    [MutationType.SetAuthToken](state, token) {
        state.authToken = token;
    },

    [MutationType.AddInProgressAction](state, action) {
        state.inProgressActions.push(action);
    },

    [MutationType.RemoveInProgressAction](state, action) {
        state.inProgressActions = state.inProgressActions.filter((currentInProgressAction) => currentInProgressAction !== action);
    },

    [MutationType.SetPeriodPickerDateString](state, dateString) {
        state.periodPickerDateString = dateString;
    },

    [MutationType.InitPeriodPicker](state) {
        if (state.periodPickerDateString) {
            return;
        }

        const periodEnd = new Date();
        periodEnd.setDate(periodEnd.getDate());
        const periodStart = new Date();
        periodStart.setDate(periodStart.getDate() - 13 * 7);

        state.periodPickerDateString = `${periodStart.toISOString()} - ${periodEnd.toISOString()}`;
    },

    [MutationType.SetPendingChangesToNotifications](state, pendingChangesToNotifications: boolean) {
        state.pendingChangesToNotifications = pendingChangesToNotifications;
    },

    [MutationType.SetFormRecentlySubmitted](state, recentlySubmitted: boolean) {
        state.formRecentlySubmitted = recentlySubmitted;
    },

    [MutationType.SetTreatmentProcedures](state, treatmentProcedures: TreatmentProcedure[]) {
        state.treatmentProcedures = treatmentProcedures;
    },

    [MutationType.SetTreatments](state, treatments: Treatment[]) {
        state.treatments = treatments;
    },

    [MutationType.SetTreatmentHistory](state, treatmentHistory: TreatmentHistory) {
        state.treatmentHistory = treatmentHistory;
    },

    [MutationType.SetAssignmentTemplates](state, assignmentTemplates: AssignmentTemplate[]) {
        state.assignmentTemplates = assignmentTemplates;
    },
};

// We don't watch to initialize the selected sites until all sites are loaded
function initSiteSelections(state: State) {
    const hasSelectedLocations = state.selectedFarmIds.length || state.selectedBarnIds.length || state.selectedBatchIds.length || state.selectedSectionIds.length;

    if (state.farms.length && state.barns.length && !hasSelectedLocations && (!store.getters.hasModuleEnabled(Module.Sections) || state.sections !== null)) {
        state.selectedFarmIds = state.farms.map((currentFarm) => currentFarm.farm_id);
        resetSelectedSites(state);
    }
}

function resetSelectedSites(state: State) {
    const barnIds: number[] = [];
    const sectionIds: number[] = [];
    const batchIds: number[] = [];

    state.barns.forEach((barn) => {
        if (!state.selectedFarmIds.includes(barn.farm_id) || barn.is_weaners === (state.pigType !== PigType.Weaners)) {
            return;
        }

        if (barn.is_batch_production) {
            const batchIdsToAdd = barn.open_batches?.map((batch) => batch.batch_id) || [];

            batchIds.push(...batchIdsToAdd);
        }

        const sectionsIdsToAdd = state.sections?.filter((section) => section.barn_id === barn.barn_id).map((section) => section.id) || [];

        barnIds.push(barn.barn_id);
        sectionIds.push(...sectionsIdsToAdd);
    });

    state.selectedBarnIds = barnIds;
    state.selectedBatchIds = batchIds;
    state.selectedSectionIds = sectionIds;
}
