<template>
    <div class="w-full grid">
        <GenericChartModal v-if="expandable" :chart-module="chartModule" :options="options" :print-subtitle="printSubtitle">
            <template #default="scope">
                <div class="grid">
                    <div
                        ref="chartElement"
                        class="w-full"
                        :class="{
                            'highcharts-dashboards-container': options?.value?.type === ChartType.Dashboard,
                            'cursor-pointer': !(options?.value?.type === ChartType.Stock || options?.value?.type === ChartType.Dashboard),
                        }"
                        @click="(event) => onChartClicked(event, scope.open)"
                    ></div>
                </div>
            </template>
        </GenericChartModal>
        <div v-else ref="chartElement" class="w-full" :class="{ 'highcharts-dashboards-container': options?.value?.type === ChartType.Dashboard }"></div>
    </div>
</template>

<script lang="ts">
import { defineComponent, watch, ComputedRef, ref, onMounted, onBeforeUnmount, unref } from 'vue';
import HighchartsMore from 'highcharts/highcharts-more';
import * as Highcharts from 'highcharts';
import isEqual from 'lodash-es/isEqual';
import { chart, stockChart, Chart } from 'highcharts';
import { board } from '@highcharts/dashboards/dashboards';
import GenericChartModal from '@/components/charts/GenericChartModal.vue';
import { ChartType } from '@/types';

export default defineComponent({
    components: { GenericChartModal },
    props: {
        options: {
            type: Object as () => ComputedRef<Highcharts.Options & { type?: ChartType }>,
            default: null,
        },
        chartModule: {
            type: Function,
            default: null,
        },
        expandable: {
            type: Boolean,
            default: true,
        },
        printSubtitle: {
            type: String as () => null | string,
            default: null,
        },
    },
    setup(props) {
        const chartElement = ref<null | HTMLElement>(null);

        HighchartsMore(Highcharts);
        if (props.chartModule) {
            props.chartModule(Highcharts);
        }

        const highcharts = ref<Chart | any>();

        let currentActiveOptions = null as null | Highcharts.Options;

        onMounted(() => {
            if (chartElement.value) {
                const unreffedOptions = unref(props.options);
                currentActiveOptions = unreffedOptions;

                if (props.options?.value?.type === ChartType.Stock) {
                    highcharts.value = stockChart(chartElement.value, unreffedOptions);
                } else if (props.options?.value?.type === ChartType.Dashboard) {
                    highcharts.value = board(chartElement.value, unreffedOptions as any);
                } else {
                    highcharts.value = chart(chartElement.value, unreffedOptions);
                }
            }
        });

        onBeforeUnmount(() => {
            if (highcharts.value && highcharts.value.destroy) {
                highcharts.value.destroy();
            }
        });

        watch(
            () => props.options,
            (newValue: ComputedRef<Highcharts.Options>) => {
                const unreffedOptions = unref(newValue);
                if (highcharts.value && currentActiveOptions && !isEqual(unreffedOptions, currentActiveOptions)) {
                    currentActiveOptions = unreffedOptions;
                    highcharts.value.update(unreffedOptions, true, true, true);
                }
            },
            { deep: true }
        );

        const onChartClicked = (event: any, open: CallableFunction) => {
            if (props.options?.value?.type === ChartType.Stock || props.options?.value?.type === ChartType.Dashboard) {
                return;
            }
            if (chartElement.value && (unref(chartElement) as any).querySelector('.highcharts-legend')?.contains(event.target)) {
                return;
            }
            open();
        };

        return { chartElement, highcharts, onMounted, onBeforeUnmount, onChartClicked, ChartType };
    },
});
</script>
