<template>
    <div>
        <div v-if="options?.value?.series?.length || options?.value?.type === ChartType.Dashboard">
            <slot></slot>
        </div>
        <div v-else class="min-h-20 flex justify-center">
            <h4 class="text-lg font-medium my-auto">{{ $t('layouts.dashboard.charts.noChartData') }}</h4>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ComputedRef } from 'vue';
import GenericChart from '@/components/charts/GenericChart.vue';
import { ChartType } from '@/types';

export default defineComponent({
    components: { GenericChart },
    props: {
        options: {
            type: Object as () => ComputedRef<Highcharts.Options & { type?: ChartType }>,
            default: null,
        },
    },
    setup() {
        return {
            ChartType,
        };
    },
});
</script>
