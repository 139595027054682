import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7241c37a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "w-full flex space-x-2 content-center" }
const _hoisted_3 = {
  style: {"margin-top":"2px"},
  class: "font-semibold text-lg hidden lg:block"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EventButton = _resolveComponent("EventButton")!

  return (_openBlock(), _createBlock(_component_EventButton, {
    "dropdown-class": "w-46",
    events: _ctx.batchEvents
  }, {
    button: _withCtx(({ toggleExpandDropdown }) => [
      _createElementVNode("button", {
        class: "expand-button bg-primary-300 rounded-xl py-2 text-primary-800 cursor-pointer",
        onClick: toggleExpandDropdown
      }, [
        _createElementVNode("span", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('layouts.dashboard.topBar.batchButton.label')), 1)
        ])
      ], 8, _hoisted_1)
    ]),
    _: 1
  }, 8, ["events"]))
}