<template>
    <MainViewCard>
        <div class="flex py-4">
            <span class="text-lg font-medium ml-6 my-auto">{{ $t('views.notifications.content.tableHeader') }}</span>
            <span class="flex-1"></span>
            <span class="text-right mr-4 w-80">
                <SearchInput v-model:value="search" :label="$t('views.notifications.content.searchFieldLabel')"></SearchInput>
            </span>
            <span class="mr-6">
                <FilterModal
                    v-model:selected-ids="selectedNotificationTypeIds"
                    :types="selectableNotificationTypes"
                    :header="$t('views.notifications.content.table.filterModal.header')"
                    :sub-header="$t('views.notifications.content.table.filterModal.subheader')"
                >
                    <template #default="{ scope }">
                        <button type="button" class="flex items-center bg-primary-700 text-primary-800 bg-opacity-20 rounded-lg py-2 px-3" @click="scope.open">
                            <span class="m-auto pr-2">
                                <Icon :src="IconSource.Filter"></Icon>
                            </span>
                            <span class="font-medium">{{ $t('views.notifications.content.table.filterModal.buttonLabel') }}</span>
                            <span v-if="selectableNotificationTypes.length && selectedNotificationTypeIds.length !== selectableNotificationTypes.length" class="bg-primary-800 rounded-full text-white font-medium leading-none px-2 py-1 ml-2">{{ selectedNotificationTypeIds.length }}</span>
                        </button>
                    </template>
                </FilterModal>
            </span>
        </div>
        <CustomTable :items="filteredItems" :headers="headers">
            <template #header_icon_url>
                <th class="w-16"></th>
            </template>

            <template #header_actions>
                <th class="w-6"></th>
            </template>

            <template #icon_url="{ item }">
                <td class="w-16 my-auto relative">
                    <Icon class="w-10 h-10" :src="item.icon_url"></Icon>
                    <div v-if="item.category === 'NfnCategory.Form' && !item.disabled && !item.rejected && !item.approved" class="bg-red-500 w-2.5 h-2.5 rounded-full absolute top-0.5 right-6"></div>
                </td>
            </template>

            <template #message="{ item, getHeaderClass, getWidth, header }">
                <td class="flex-1" :class="`${getHeaderClass(header, false)} my-auto ${item.read ? '' : 'font-semibold'}`" :style="`max-width: ${getWidth(header)}px; width: 100%;`">
                    {{ item.message }}
                </td>
            </template>

            <template #publish_date="{ item, getHeaderClass, getWidth, header }">
                <td class="flex-1" :class="`${getHeaderClass(header, false)} my-auto`" :style="`max-width: ${getWidth(header)}px; width: 100%;`">
                    {{ $d(item.publish_date, 'short') }}
                </td>
            </template>

            <template #actions="{ item }">
                <td class="w-6 my-auto">
                    <ActionModal
                        v-if="item.category === 'NfnCategory.Form' && item.resource"
                        :step-config="actionModalStepConfig"
                        :header="{ icon_url: item.resource.icon_url, text: item.resource.text }"
                        :initial-state="{
                            location: null,
                            form: getFormForNotification(item, forms)?.form,
                            confirmed: false,
                            farm: null,
                            record_id: null,
                            event: null,
                            notification: item,
                        }"
                        :initial-step-index="2"
                    >
                        <template #default="{ open }">
                            <span class="flex justify-end">
                                <button type="button" class="p-2 -mr-2" @click="onFormNotificationClicked(item, open)">
                                    <Icon class="h-3 w-3" :src="IconSource.Right"></Icon>
                                </button>
                            </span>
                        </template>
                    </ActionModal>
                    <span v-else class="flex justify-end">
                        <TextNotificationModal :notification="item">
                            <template #default="scope">
                                <button type="button" class="p-2 -mr-2" @click="scope.open">
                                    <Icon class="h-3 w-3" :src="IconSource.Right"></Icon>
                                </button>
                            </template>
                        </TextNotificationModal>
                    </span>
                </td>
            </template>
        </CustomTable>
    </MainViewCard>
</template>

<script lang="ts">
import { defineComponent, ref, onBeforeUnmount, computed, watch } from 'vue';
import { useToast } from 'vue-toastification';
import MainViewCard from '@/components/common/MainViewCard.vue';
import CustomTable from '@/components/ui/CustomTable.vue';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';
import { Notification, Form, NotificationType, NotificationTypeId, TableHeader, Meta, IconSource } from '@/types';
import SearchInput from '@/components/ui/SearchInput.vue';
import ActionModal from '@/components/common/actionModal/ActionModal.vue';
import TextNotificationModal from '@/views/dashboard/notifications/notificationsIndex/TextNotificationModal.vue';
import FilterModal from '@/components/common/FilterModal.vue';
import { i18n } from '@/plugins/internationalization/i18n';
import useFilterableTable from '@/composables/useFilterableTable';

export default defineComponent({
    components: { MainViewCard, CustomTable, SearchInput, ActionModal, TextNotificationModal, FilterModal },
    setup() {
        const toast = useToast();

        store.dispatch(ActionType.GetNotifications, { options: { ignoreCache: true } });

        const notificationTypes = ref([
            {
                icon_url: IconSource.Read,
                name: i18n.global.t('views.notifications.content.table.filterModal.types.read'),
                id: NotificationTypeId.read,
                filterCallback: (notification: Notification) => notification.read,
            },
            {
                icon_url: IconSource.Unread,
                name: i18n.global.t('views.notifications.content.table.filterModal.types.unread'),
                id: NotificationTypeId.unread,
                filterCallback: (notification: Notification) => !notification.read,
            },
            {
                icon_url: IconSource.Declined,
                name: i18n.global.t('views.notifications.content.table.filterModal.types.rejected'),
                id: NotificationTypeId.rejected,
                filterCallback: (notification: Notification) => notification.rejected,
            },
            {
                icon_url: IconSource.Accepted,
                name: i18n.global.t('views.notifications.content.table.filterModal.types.approved'),
                id: NotificationTypeId.approved,
                filterCallback: (notification: Notification) => notification.approved,
            },
            {
                icon_url: IconSource.ActionRequired,
                name: i18n.global.t('views.notifications.content.table.filterModal.types.requiresAction'),
                id: NotificationTypeId.requiresAction,
                filterCallback: (notification: Notification) => notification.category === 'NfnCategory.Form' && !(notification.approved || notification.disabled),
            },
        ]);

        const selectedNotificationTypeIds = ref<NotificationTypeId[]>(notificationTypes.value.map((notificationType) => notificationType.id));
        const selectableNotificationTypes = ref<NotificationType[]>(notificationTypes.value);
        const selectedNotificationTypes = computed(() => selectableNotificationTypes.value.filter((selectableNotificationType) => selectedNotificationTypeIds.value.includes(selectableNotificationType.id)));

        const actionModalStepConfig = ref([{}, {}, { rejectable: true, submitActionType: ActionType.PutForm }, {}]);
        const forms = ref<{ [key: string]: { form: null | Form } }>({});

        const headers = computed<TableHeader[]>(() => [
            {
                text: '',
                value: 'icon_url',
                align: 'left',
                sortable: false,
                searchable: false,
            },
            {
                text: i18n.global.t('views.notifications.content.table.headers.message'),
                value: 'message',
                align: 'left',
                sortable: true,
                searchable: true,
            },
            {
                text: i18n.global.t('views.notifications.content.table.headers.publishDate'),
                value: 'publish_date',
                align: 'left',
                sortable: true,
                searchable: true,
                headerClass: 'max-w-52',
            },
            {
                text: '',
                value: 'actions',
                align: 'right',
                sortable: false,
                searchable: false,
            },
        ]);
        const items = computed(() =>
            store.getters.getNotifications.filter((currentNotification) => selectedNotificationTypes.value.some((currentNotificationType) => currentNotificationType.filterCallback(currentNotification)))
        );
        const { search, filteredItems } = useFilterableTable(items, headers);

        const meta = computed(() => store.state.meta);
        watch(meta, (newValue: Meta | null, prevValue: Meta | null) => {
            if (
                store.state.pendingChangesToNotifications ||
                newValue === null ||
                prevValue === null ||
                (newValue.notifications.unread <= prevValue.notifications.unread && newValue?.notifications.unhandled === prevValue.notifications.unhandled)
            ) {
                return;
            }

            toast.info(i18n.global.t('views.notifications.content.notificationsChangedSnackbarMessage'), {
                timeout: false,
                onClick: () => {
                    store.dispatch(ActionType.GetNotifications, { options: { ignoreCache: true } });
                },
            });
        });

        async function onFormNotificationClicked(notification: Notification, open: CallableFunction) {
            if (!notification.resource) {
                return;
            }

            const form: Form = await store.dispatch(ActionType.GetForm, {
                form: { form_id: notification.resource.resource_id, nref: notification.resource.reference_id },
            });

            if (form) {
                forms.value[notification.resource.reference_id] = { form };
                open();
            }
        }

        function getFormForNotification(notification: Notification, forms: { [key: string]: { form: null | Form } }) {
            if (!notification.resource) {
                return {
                    form: null,
                };
            }
            return forms[notification.resource.reference_id];
        }

        onBeforeUnmount(() => {
            store.dispatch(ActionType.ReadAllNotifications);
        });

        return {
            actionModalStepConfig,
            selectedNotificationTypeIds,
            selectableNotificationTypes,
            forms,
            headers,
            search,
            filteredItems,
            onFormNotificationClicked,
            getFormForNotification,
        };
    },
});
</script>
