import { Options } from 'highcharts';
import { computed, ComputedRef, ref, Ref, watch, unref } from 'vue';
import isEqual from 'lodash-es/isEqual';
import { Chart, GraphOptions } from '@/types';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';

export default function useChartAPI(chartType: Chart, fetchActionType: ActionType, addParameters?: null | Ref<string>) {
    const options = ref(null) as Ref<null | Options>;
    const series = ref([]) as Ref<{ name: string; data: any[][] }[]>;
    const title = ref('');

    const graphPreconditions = computed(() => ({
        siteIds: store.getters.getSelectedSiteIds as number[],
        selectedPeriod: store.getters.getSelectedPeriod as string,
    }));

    const prevGraphPreconditions = ref(null) as Ref<null | { siteIds: number[]; selectedPeriod: string }>;

    watch(
        () => graphPreconditions,
        (newValue) => {
            if ((graphPreconditions.value.siteIds.length || prevGraphPreconditions.value?.siteIds.length) && newValue.value.selectedPeriod.length && !isEqual(newValue.value, prevGraphPreconditions.value)) {
                store.dispatch(fetchActionType, { form: { chartType, addParameters: unref(addParameters) } }).then((graphResponse: GraphOptions) => {
                    options.value = graphResponse.options;
                    title.value = graphResponse.title;
                    series.value = graphResponse.series;
                });
            }
            prevGraphPreconditions.value = newValue.value;
        },
        { deep: true, immediate: true }
    );

    if (addParameters) {
        watch(addParameters, (newValue: null | undefined | Ref<string>) => {
            store.dispatch(fetchActionType, { form: { chartType, addParameters: unref(newValue) } }).then((graphResponse: GraphOptions) => {
                options.value = graphResponse.options;
                title.value = graphResponse.title;
                series.value = graphResponse.series;
            });
        });
    }

    return { options, series, title };
}
