import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, withKeys as _withKeys, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "w-64 h-full bg-primary-700 cursor-pointer" }
const _hoisted_2 = { class: "pb-2 pt-4 px-4 cursor-default flex-col space-y-2" }
const _hoisted_3 = { class: "flex space-x-2" }
const _hoisted_4 = {
  key: 0,
  class: "flex flex-col divide-y"
}
const _hoisted_5 = { class: "w-full h-full flex" }
const _hoisted_6 = { class: "w-full flex space-x-2" }
const _hoisted_7 = { class: "my-auto" }
const _hoisted_8 = { class: "flex flex-col pt-1 my-auto" }
const _hoisted_9 = {
  key: 0,
  class: "text-white text-base align-self-end max-h-12 overflow-y-hidden"
}
const _hoisted_10 = { class: "text-white opacity-50 text-sm" }
const _hoisted_11 = { class: "content-center justify-end grid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchInput = _resolveComponent("SearchInput")!
  const _component_NestedLocation = _resolveComponent("NestedLocation")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_ExpandIcon = _resolveComponent("ExpandIcon")!
  const _component_DropDown = _resolveComponent("DropDown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_DropDown, {
      expand: _ctx.expandDropdown,
      "onUpdate:expand": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.expandDropdown) = $event)),
      "dropdown-class": "w-96 !origin-top-left !right-auto -mt-1 overflow-y-auto max-h-160"
    }, {
      "dropdown-header": _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_SearchInput, {
            value: _ctx.search,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
            label: _ctx.$t('layouts.dashboard.topBar.locationPicker.searchFieldLabel'),
            onKeydown: _cache[1] || (_cache[1] = _withKeys(_withModifiers(($event: any) => (_ctx.search = ''), ["prevent"]), ["esc"]))
          }, null, 8, ["value", "label"]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("button", {
              class: "bg-primary-300 transition-width text-primary-800 font-medium text-sm py-2 px-2 rounded whitespace-nowrap w-7/12",
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.toggleExpandFarms && _ctx.toggleExpandFarms(...args)))
            }, _toDisplayString(_ctx.allFarmsAreExpanded ? _ctx.$t('layouts.dashboard.topBar.locationPicker.collapseAllFarmsButtonLabel') : _ctx.$t('layouts.dashboard.topBar.locationPicker.expandAllFarmsButtonLabel')), 1),
            _createElementVNode("button", {
              class: "bg-primary-300 transition-width text-primary-800 font-medium text-sm py-2 px-2 rounded whitespace-nowrap w-5/12",
              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.toggleAllLocationsSelected && _ctx.toggleAllLocationsSelected(...args)))
            }, _toDisplayString(_ctx.allLocationsAreSelected ? _ctx.$t('layouts.dashboard.topBar.locationPicker.deselectAllButtonLabel') : _ctx.$t('layouts.dashboard.topBar.locationPicker.selectAllButtonLabel')), 1)
          ])
        ])
      ]),
      "dropdown-content": _withCtx(() => [
        (_ctx.locationPickerState)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.locationPickerState, (farm) => {
                return (_openBlock(), _createBlock(_component_NestedLocation, {
                  ref_for: true,
                  ref: _ctx.addNestedLocationToRefs,
                  key: farm.id,
                  "location-picker-state": _ctx.locationPickerState,
                  option: farm,
                  path: `${farm.id}`,
                  nested: _ctx.isNested,
                  class: "text-left flex",
                  onLocationToggled: _ctx.onLocationToggled,
                  onToggleExpand: _ctx.onFarmToggleExpand
                }, null, 8, ["location-picker-state", "option", "path", "nested", "onLocationToggled", "onToggleExpand"]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]),
      default: _withCtx(() => [
        _createElementVNode("button", {
          type: "button",
          class: "h-full w-full flex px-4 py-4 text-left",
          onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.toggleExpandDropdown && _ctx.toggleExpandDropdown(...args)))
        }, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_Icon, {
                  class: "min-w-7",
                  src: _ctx.IconSource.SelectFarms
                }, null, 8, ["src"])
              ]),
              _createElementVNode("div", _hoisted_8, [
                (_ctx.user)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.user.directory_name), 1))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t('layouts.dashboard.topBar.locationPicker.selectedSites').replace('$NUMBER_OF_SELECTED_SITES', `${_ctx.locallySelectedIds.farms.length}`)), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createVNode(_component_ExpandIcon, {
                color: "white",
                expand: _ctx.expandDropdown
              }, null, 8, ["expand"])
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["expand"])
  ]))
}