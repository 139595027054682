module.exports = {
    primary: {
        300: '#e4c4c4',
        500: '#F0DEDF',
        700: '#a73233',
        800: '#9E2A2B',
    },
    pigs: '#F4BCC8',
    deaths: '#E3563A',
    feed: {
        400: '#9BC195',
        600: '#CDDDDD',
    },
    slaughters: '#E6A439',
    status: '#8EB8E5',
    transfer: '#37286E',
    batch: '#224678',
    medicine: '#37286E',
    loader: '#89d3e0',
};
