import get from 'lodash-es/get';
import { i18n } from '@/plugins/internationalization/i18n';
import { TableHeader } from '@/types';

export default function useGetItemProperty(header: TableHeader, item: any, options?: { numberFormat?: string }) {
    const property = get(item, header.value);
    if (property === null || property === undefined) {
        return '-';
    }
    if (typeof property === 'number') {
        return i18n.global.n(property, options?.numberFormat || '');
    }
    if (header.type === 'date') {
        return i18n.global.d(property, 'short');
    }
    return property;
}
