import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = {
  key: 0,
  class: "absolute rounded-lg inset-0 flex justify-center items-center",
  "aria-hidden": "true"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Spinner = _resolveComponent("Spinner")!

  return (_openBlock(), _createElementBlock("button", {
    type: "button",
    class: _normalizeClass(["bg-primary-700 text-primary-800 bg-opacity-20 transition-colors duration-300 rounded-lg py-2 px-3 flex relative", { 'hover:bg-opacity-30': !_ctx.disabled && !_ctx.loading, 'cursor-default': _ctx.disabled || _ctx.loading }]),
    disabled: _ctx.disabled || _ctx.loading,
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)))
  }, [
    _renderSlot(_ctx.$slots, "content", {}, () => [
      _createElementVNode("span", {
        class: _normalizeClass(["m-auto pr-2", { invisible: _ctx.loading }])
      }, [
        _createVNode(_component_Icon, { src: _ctx.iconSource }, null, 8, ["src"])
      ], 2),
      _createElementVNode("span", {
        class: _normalizeClass([_ctx.textClass, { invisible: _ctx.loading }])
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 2)
    ]),
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_Spinner, { class: "h-6 w-6 mx-auto" })
        ]))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}