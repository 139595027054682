import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, withKeys as _withKeys, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = { class: "absolute -mt-2 right-5 top-1/2" }
const _hoisted_3 = { class: "absolute -mt-4 top-full left-0 w-full bg-white border z-30 rounded" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 1,
  type: "button",
  class: "cursor-default p-3 text-gray-400 h-full inline w-full text-left"
}
const _hoisted_6 = {
  key: 1,
  type: "button",
  class: "cursor-default py-3 pr-3 pl-7 text-gray-400 h-full inline w-full text-left"
}
const _hoisted_7 = { key: 2 }
const _hoisted_8 = {
  type: "button",
  class: "py-3 pr-3 pl-12 h-full inline w-full text-left cursor-pointer hover:bg-gray-100"
}
const _hoisted_9 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomInput = _resolveComponent("CustomInput")!
  const _component_ExpandIcon = _resolveComponent("ExpandIcon")!
  const _component_ListboxButton = _resolveComponent("ListboxButton")!
  const _component_ListboxOption = _resolveComponent("ListboxOption")!
  const _component_ListboxOptions = _resolveComponent("ListboxOptions")!
  const _component_Listbox = _resolveComponent("Listbox")!

  return (_openBlock(), _createBlock(_component_Listbox, {
    modelValue: _ctx.computedValue,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.computedValue) = $event))
  }, {
    default: _withCtx(({ open }) => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_ListboxButton, {
          class: "overflow-hidden relative w-full shadow-none",
          disabled: _ctx.disabled,
          onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.buttonFocused = true)),
          onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.buttonFocused = false))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_CustomInput, {
              ref: "input-field",
              value: _ctx.inputDisplayValue,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputDisplayValue) = $event)),
              "show-required-indication": _ctx.showRequiredIndication,
              active: open,
              required: _ctx.required,
              prepend: _ctx.prepend,
              "focus-highlight": _ctx.buttonFocused,
              rules: [],
              "input-type": "text",
              label: _ctx.label,
              details: _ctx.hint,
              disabled: _ctx.disabled,
              "show-hint": _ctx.showHint,
              "error-state": !_ctx.validInput,
              "tab-index": "-1",
              readonly: "",
              cursor: "cursor-pointer"
            }, null, 8, ["value", "show-required-indication", "active", "required", "prepend", "focus-highlight", "label", "details", "disabled", "show-hint", "error-state"]),
            _withDirectives(_createElementVNode("span", _hoisted_2, [
              _createVNode(_component_ExpandIcon, {
                expand: open,
                color: "black"
              }, null, 8, ["expand"])
            ], 512), [
              [_vShow, !_ctx.disabled]
            ])
          ]),
          _: 2
        }, 1032, ["disabled"]),
        _createVNode(_Transition, {
          "enter-active-class": "transition duration-200 ease-out",
          "enter-from-class": "transform scale-95 opacity-0",
          "enter-to-class": "transform scale-100 opacity-100",
          "leave-active-class": "transition duration-100 ease-out",
          "leave-from-class": "transform scale-100 opacity-100",
          "leave-to-class": "transform scale-95 opacity-0"
        }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("div", _hoisted_3, [
              _createVNode(_component_ListboxOptions, {
                static: "",
                class: _normalizeClass([[_ctx.dropdownHeightClass], "outline-none overflow-y-auto"]),
                onKeydown: _cache[3] || (_cache[3] = _withKeys(_withModifiers(() => {}, ["stop"]), ["esc"]))
              }, {
                default: _withCtx(() => [
                  (_ctx.groups)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        (_ctx.allowEmpty)
                          ? (_openBlock(), _createBlock(_component_ListboxOption, {
                              key: 0,
                              value: _ctx.returnObject ? null : ''
                            }, {
                              default: _withCtx(({ selected, active }) => [
                                _createElementVNode("button", {
                                  type: "button",
                                  class: _normalizeClass([[selected ? 'bg-primary-300 bg-opacity-40' : 'hover:bg-gray-100', active ? 'bg-gray-100' : ''], "p-3 h-12 inline w-full text-left cursor-pointer"])
                                }, null, 2)
                              ]),
                              _: 1
                            }, 8, ["value"]))
                          : _createCommentVNode("", true),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredItems, (itemGroup, itemGroupIndex) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: `item-group-${itemGroupIndex}`
                          }, [
                            (
                                        _ctx.groupSelectOptions.selectableCondition(itemGroup) ||
                                        (typeof _ctx.groupSelectOptions.intermediateSelectableCondition === 'function' && _ctx.groupSelectOptions.intermediateSelectableCondition(itemGroup))
                                    )
                              ? (_openBlock(), _createBlock(_component_ListboxOption, {
                                  key: 0,
                                  value: _ctx.groupSelectOptions.value(itemGroup)
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("button", {
                                      type: "button",
                                      class: _normalizeClass([
                                            'p-3 h-full inline w-full text-left cursor-pointer hover:bg-gray-100',
                                            { 'text-gray-400': typeof _ctx.groupSelectOptions.intermediateSelectableCondition === 'function' && _ctx.groupSelectOptions.intermediateSelectableCondition(itemGroup) },
                                        ])
                                    }, _toDisplayString(itemGroup[_ctx.itemText]), 3)
                                  ]),
                                  _: 2
                                }, 1032, ["value"]))
                              : (_openBlock(), _createElementBlock("button", _hoisted_5, _toDisplayString(itemGroup[_ctx.itemText]), 1)),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(itemGroup[_ctx.groupKey], (item, itemIndex) => {
                              return (_openBlock(), _createElementBlock("div", {
                                key: `item-${itemGroupIndex}-${itemIndex}`
                              }, [
                                (
                                            _ctx.subGroupSelectOptions.selectableCondition(item) ||
                                            (typeof _ctx.subGroupSelectOptions.intermediateSelectableCondition === 'function' && _ctx.subGroupSelectOptions.intermediateSelectableCondition(item))
                                        )
                                  ? (_openBlock(), _createBlock(_component_ListboxOption, {
                                      key: 0,
                                      value: _ctx.subGroupSelectOptions.value(item)
                                    }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("button", {
                                          type: "button",
                                          class: _normalizeClass([
                                                'py-3 pr-3 pl-7 h-full inline w-full text-left cursor-pointer hover:bg-gray-100',
                                                { 'text-gray-400': typeof _ctx.subGroupSelectOptions.intermediateSelectableCondition === 'function' && _ctx.subGroupSelectOptions.intermediateSelectableCondition(item) },
                                            ])
                                        }, _toDisplayString(item[_ctx.groupTextKey]), 3)
                                      ]),
                                      _: 2
                                    }, 1032, ["value"]))
                                  : (_openBlock(), _createElementBlock("button", _hoisted_6, _toDisplayString(item[_ctx.groupTextKey]), 1)),
                                (item[_ctx.subGroupKey] && item[_ctx.subGroupKey].length)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item[_ctx.subGroupKey], (subItem, subItemIndex) => {
                                        return (_openBlock(), _createElementBlock("div", {
                                          key: `sub-item-${itemGroupIndex}-${itemIndex}-${subItemIndex}`
                                        }, [
                                          _createVNode(_component_ListboxOption, {
                                            value: _ctx.returnObject ? subItem : subItem[_ctx.subGroupValue]
                                          }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("button", _hoisted_8, _toDisplayString(subItem[_ctx.subGroupTextKey]), 1)
                                            ]),
                                            _: 2
                                          }, 1032, ["value"])
                                        ]))
                                      }), 128))
                                    ]))
                                  : _createCommentVNode("", true)
                              ]))
                            }), 128))
                          ]))
                        }), 128))
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                        (_ctx.allowEmpty)
                          ? (_openBlock(), _createBlock(_component_ListboxOption, {
                              key: 0,
                              value: _ctx.returnObject ? null : ''
                            }, {
                              default: _withCtx(({ selected, active }) => [
                                _createElementVNode("button", {
                                  type: "button",
                                  class: _normalizeClass([[selected ? 'bg-primary-300 bg-opacity-40' : 'hover:bg-gray-100', active ? 'bg-gray-100' : ''], "p-3 h-12 inline text-left w-full cursor-pointer"])
                                }, null, 2)
                              ]),
                              _: 1
                            }, 8, ["value"]))
                          : _createCommentVNode("", true),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredItems, (item) => {
                          return (_openBlock(), _createBlock(_component_ListboxOption, {
                            key: item[_ctx.itemValue],
                            value: _ctx.returnObject ? item : item[_ctx.itemValue]
                          }, {
                            default: _withCtx(({ selected, active }) => [
                              _createElementVNode("button", {
                                type: "button",
                                class: _normalizeClass([[selected ? 'bg-primary-300 bg-opacity-40' : 'hover:bg-gray-100', active ? 'bg-gray-100' : ''], "p-3 h-full inline text-left w-full cursor-pointer"])
                              }, _toDisplayString(item[_ctx.itemText]), 3)
                            ]),
                            _: 2
                          }, 1032, ["value"]))
                        }), 128))
                      ]))
                ]),
                _: 1
              }, 8, ["class"])
            ], 512), [
              [_vShow, open]
            ])
          ]),
          _: 2
        }, 1024)
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}