<template>
    <button
        type="button"
        class="bg-primary-700 text-primary-800 bg-opacity-20 transition-colors duration-300 rounded-lg py-2 px-3 flex relative"
        :class="{ 'hover:bg-opacity-30': !disabled && !loading, 'cursor-default': disabled || loading }"
        :disabled="disabled || loading"
        @click="handleClick"
    >
        <slot name="content">
            <span class="m-auto pr-2" :class="{ invisible: loading }">
                <Icon :src="iconSource"></Icon>
            </span>
            <span :class="[textClass, { invisible: loading }]">
                <slot></slot>
            </span>
        </slot>
        <div v-if="loading" class="absolute rounded-lg inset-0 flex justify-center items-center" aria-hidden="true">
            <Spinner class="h-6 w-6 mx-auto"></Spinner>
        </div>
    </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { IconSource } from '@/types';
import Spinner from './Spinner.vue';

export default defineComponent({
    components: { Spinner },
    props: {
        textClass: {
            type: String,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        iconSource: {
            type: [String, Number],
            default: IconSource.AddAction,
        },
    },
    emits: ['click'],
    setup(props, { emit }) {
        function handleClick(event: MouseEvent) {
            if (props.disabled || props.loading) {
                event.preventDefault();

                return;
            }

            emit('click', event);
        }

        return {
            handleClick,
        };
    },
});
</script>
