export enum Module {
    Debug = 'Debug',
    Sections = 'Sections',
    Medicine = 'Medicine',
    Assignments = 'Assignments',
    UserManagement = 'UserManagement',
    RawMaterialsRegister = 'RawMaterialsRegister',
    StatusEntries = 'StatusEntries',
    BonusPayments = 'BonusPayments',
    PriceListings = 'PriceListings',
}
