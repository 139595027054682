<template>
    <Modal close-esc-event="keydown" :close-button="false" modal-class=" m-auto border-0" mask-class="grid" @opening="onOpening" @closing="onClose">
        <template #toggle="scope">
            <CustomButton class="px-6" @click="scope.open">
                {{ $t('views.reports.reportsPage.eControlModal.modalButtonLabel') }}
            </CustomButton>
        </template>

        <template #header="scope">
            <CloseModalButton @close="scope.close"></CloseModalButton>
        </template>

        <template #body>
            <div class="bg-gray-100 py-6 px-8 rounded-xl">
                <transition name="fade" mode="out-in">
                    <div v-if="pdf" class="space-y-6">
                        <h2 class="text-xl font-semibold text-center">{{ header }}</h2>
                        <div class="flex space-x-4">
                            <button
                                v-for="eControlAction of eControlActions"
                                :key="eControlAction.text"
                                type="button"
                                class="bg-primary-700 text-primary-800 bg-opacity-20 rounded-lg py-4 px-3 flex w-40 justify-center space-x-2"
                                @click="eControlAction.onClick"
                            >
                                <span class="my-auto justify-self-end">
                                    <Icon :src="eControlAction.icon"></Icon>
                                </span>
                                <span class="my-auto font-medium justify-self-start">{{ eControlAction.text }}</span>
                            </button>
                        </div>
                    </div>
                    <div v-else class="w-80 space-y-6 flex-col">
                        <h2 class="text-xl font-semibold text-center">{{ header }}</h2>
                        <div class="flex justify-center mx-auto">
                            <Spinner class="w-16 h-16" :active="true"></Spinner>
                        </div>
                    </div>
                </transition>
            </div>
        </template>
    </Modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Modal } from '@makeabledk/vue-ui/components/modal';
import { useToast } from 'vue-toastification';
import CustomButton from '@/components/ui/CustomButton.vue';
import Spinner from '@/components/ui/Spinner.vue';
import CloseModalButton from '@/components/common/CloseModalButton.vue';
import { ActionType } from '@/plugins/store/actions';
import { store } from '@/plugins/store';
import { ReportViewState, IconSource } from '@/types';

export default defineComponent({
    components: { Modal, CustomButton, Spinner, CloseModalButton },
    props: {
        state: {
            type: Object as () => ReportViewState,
            required: true,
        },
    },
    data() {
        return {
            pdf: null as null | Blob,
            toast: useToast(),
        };
    },
    computed: {
        header(): string {
            return this.pdf ? this.$t('views.reports.reportsPage.eControlModal.generatedModalHeader') : this.$t('views.reports.reportsPage.eControlModal.generatingModalHeader');
        },
        eControlActions(): { icon: string | number; text: string; onClick: CallableFunction }[] {
            return [
                {
                    icon: IconSource.DownloadPDF,
                    text: this.$t('views.reports.reportsPage.eControlModal.savePdfButtonLabel'),
                    onClick: () => {
                        this.downloadPdf();
                    },
                },
                {
                    icon: IconSource.ShowPDF,
                    text: this.$t('views.reports.reportsPage.eControlModal.showButtonLabel'),
                    onClick: () => {
                        this.openPdf();
                    },
                },
            ];
        },
    },
    methods: {
        onOpening() {
            store
                .dispatch(ActionType.GetEControlPDF, { form: { site_ids: this.$props.state.siteIds, dates: this.$props.state.dates } })
                .then((response) => {
                    this.pdf = new Blob([response], { type: 'application/pdf' });
                })
                .catch((error: any) => {
                    console.log(error);
                    this.toast?.error(this.$t('global.errors.unknown'));
                });
        },
        onClose() {
            this.pdf = null;
        },
        async downloadPdf() {
            if (!this.pdf) return;
            const fileURL = URL.createObjectURL(this.pdf);
            const link = document.createElement('a');
            link.href = fileURL;
            link.setAttribute('download', `${new Date().toLocaleDateString().replaceAll('.', '-')}_e-control.pdf`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        async openPdf() {
            if (!this.pdf) return;
            const fileURL = URL.createObjectURL(this.pdf);
            window.open(fileURL);
        },
    },
});
</script>
