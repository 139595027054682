<template>
    <div class="h-full min-h-60 space-y-4">
        <h2 class="text-xl font-semibold text-center">{{ header?.text || computedState.event?.name || '' }}</h2>
        <div>
            <div v-if="computedState.form" class="grid h-full">
                <div v-for="(formSection, sectionIndex) of computedState.form.sections" :key="formSection.id" :class="{ 'mt-4': formSection.name }" class="first:mt-0">
                    <div v-if="collapsibleSections[formSection.id]" class="flex gap-x-2 mb-4">
                        <div>
                            <button
                                type="button"
                                class="bg-primary-700 text-primary-800 bg-opacity-20 hover:bg-opacity-30 transition-colors duration-300 rounded-lg py-2 px-3 flex"
                                @click="onToggleExpandSectionButtonClicked(formSection)"
                            >
                                <span class="m-auto" :class="{ 'pr-2': formSection.name }">
                                    <Icon :src="formSection.collapsed ? IconSource.AddAction : IconSource.Minus"></Icon>
                                </span>
                                <span>{{ formSection.name }}</span>
                            </button>
                        </div>
                        <div v-if="dynamicSectionOptions[formSection.id]">
                            <DeleteButton @click="onDeleteSection(formSection)">
                                {{ dynamicSectionOptions[formSection.id].deleteSectionButtonLabel }}
                            </DeleteButton>
                        </div>
                    </div>
                    <ExpandCollapseTransition>
                        <div v-if="!formSection.collapsed" class="grid grid-cols-2 gap-y-1 gap-x-2">
                            <h3 v-if="sectionIndex > 0 && formSection.name" class="text-lg font-semibold mt-2 mb-4 text-center col-span-2">{{ formSection.name }}</h3>
                            <DynamicFormElement
                                v-for="(formElement, elementIndex) of formSection.children"
                                :ref="`${formSection.id}_${formElement.id}`"
                                :key="formElement.id"
                                v-model:element="formSection.children[elementIndex]"
                                :precondition-disabled="getPreconditionDisabled(formElement, formSection)"
                                :precondition-data="getPreconditionData(formElement, formSection)"
                                :rules="getRules(formElement)"
                                :details="inputDetails[sectionIndex][elementIndex]"
                                :section-id="formSection.id"
                                :assignment-id="computedState.assignment_id"
                                @input="validateForm"
                                @clone-section="onCloneSection"
                                @backend-retrieval="(triggeredElement) => onBackendRetrieval(triggeredElement, formSection)"
                            ></DynamicFormElement>
                        </div>
                    </ExpandCollapseTransition>
                </div>
                <div v-if="subassignments" class="mt-2 mb-4">
                    <h3 class="text-lg font-semibold mb-3 w-full break-all">{{ $t('global.forms.steps.dynamicForm.sectionHeadings.subAssignments') }} ({{ subassignments.length }})</h3>
                    <transition-group
                        tag="div"
                        enter-active-class="transition-all duration-300 ease-in"
                        leave-active-class="transition-all duration-300 ease-in"
                        enter-class="transform -translate-y-4 opacity-0"
                        leave-to-class="transform translate-y-4 opacity-0"
                    >
                        <div v-for="subassignment in subassignments" :key="subassignment.id">
                            <button
                                type="button"
                                class="flex w-full items-center justify-between bg-white hover:bg-gray-100 transition-colors border border-gray-200 rounded-xl py-3.5 px-4 space-x-4 flex mt-2 text-left"
                                :class="{ 'opacity-70 cursor-not-allowed hover:bg-white': config.editable === false }"
                                :aria-checked="completedSubassignments.includes(subassignment.id) ? 'true' : 'false'"
                                :aria-labelledby="`subassignment-label-${subassignment.id}`"
                                :disabled="config.editable === false"
                                @keydown.space.prevent="completeSubassignment(subassignment.id)"
                                @keydown.enter.prevent="completeSubassignment(subassignment.id)"
                                @click="completeSubassignment(subassignment.id)"
                            >
                                <div :id="`subassignment-label-${subassignment.id}`">
                                    {{ subassignment.description }}
                                </div>
                                <div
                                    class="flex items-center flex-shrink-0 h-8 w-8 rounded-full"
                                    :class="completedSubassignments.includes(subassignment.id) ? 'bg-green-200 bg-opacity-20' : 'border border-gray-400 bg-white'"
                                >
                                    <Icon class="m-auto h-4 opacity-0 transition-opacity" :src="IconSource.Active" :class="{ 'opacity-100': completedSubassignments.includes(subassignment.id) }"></Icon>
                                </div>
                            </button>
                        </div>
                    </transition-group>
                </div>
                <div v-if="computedAssignment && computedAssignment.is_completed === false && config.editable !== false" class="mt-4">
                    <CustomButton :loading="isCompletingAssignment" :disabled="disableButtons" @click="completeAssignment">
                        {{ $t('global.forms.steps.dynamicForm.completeButtonLabel') }}
                    </CustomButton>
                </div>
                <div v-if="config.deleteButton || config.editable !== false" class="flex space-x-4 justify-end mt-4">
                    <div>
                        <CustomButton :disabled="disableButtons || isCompletingAssignment" color-preset="white" class="px-5" @click="$emit('close')">
                            {{ $t('global.forms.steps.dynamicForm.cancelButtonLabel') }}
                        </CustomButton>
                    </div>
                    <div v-if="config.deleteButton">
                        <DeleteFormConfirmationModal v-model:state="state" :type="formType" :header="header" @delete="$emit('skip')">
                            <template #default="scope">
                                <CustomButton :disabled="disableButtons || isCompletingAssignment" color-preset="warning" class="px-5 whitespace-nowrap" @click="scope.open">
                                    {{ (computedState.form.buttons && computedState.form.buttons.delete) || deleteButtonText }}
                                </CustomButton>
                            </template>
                        </DeleteFormConfirmationModal>
                    </div>
                    <div v-if="config.editable !== false" class="flex-grow">
                        <CustomButton
                            :loading="disableButtons"
                            :disabled="!validForm || isCompletingAssignment"
                            :color-preset="computedAssignment && computedAssignment.is_completed === false ? 'secondary' : 'primary'"
                            class="px-5 max-w-full"
                            @click="onNextButtonClicked"
                        >
                            {{ (computedState.form.buttons && computedState.form.buttons.next) || $t('global.forms.steps.dynamicForm.nextButtonLabel') }}
                        </CustomButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Form } from '@makeabledk/vue-ui/support/http';
import cloneDeep from 'lodash-es/cloneDeep';
import { Form as FormType, FormElement, FormSection, MultiStepFormState, CloneFormSectionOptions, Assignment, IconSource } from '@/types';
import AddButton from '@/components/ui/AddButton.vue';
import DeleteButton from '@/components/ui/DeleteButton.vue';
import CustomButton from '@/components/ui/CustomButton.vue';
import DynamicFormElement from '@/components/common/multiStepFormSteps/DynamicFormElement.vue';
import DeleteFormConfirmationModal from '@/components/common/multiStepFormSteps/DeleteFormConfirmationModal.vue';
import dynamicFormRules from '@/components/common/multiStepFormSteps/useDynamicFormRules';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';
import ExpandCollapseTransition from '@/components/common/ExpandCollapseTransition.vue';
import { i18n } from '@/plugins/internationalization/i18n';

export default defineComponent({
    components: { AddButton, DeleteButton, DynamicFormElement, CustomButton, DeleteFormConfirmationModal, ExpandCollapseTransition },
    emits: ['update:state', 'submit', 'skip', 'update:assignment', 'close', 'force-close', 'submission'],
    props: {
        state: {
            type: Object as () => MultiStepFormState & Form<MultiStepFormState>,
            required: true,
        },
        config: {
            type: Object as () => { deleteButton?: boolean; editable?: boolean },
            default: null,
        },
        header: {
            type: Object as () => null | { icon_url: string; text: string },
            default: null,
        },
        assignment: {
            type: Object as () => null | Assignment,
            default: null,
        },
    },
    data() {
        return {
            validForm: false,
            initialFormState: cloneDeep(this.$props.state.form),
            dynamicallyAddedSections: {} as { [key: string]: number },
            collapsibleSections: {} as { [key: string]: boolean },
            validationDebounceTimeout: null as null | NodeJS.Timeout,
            disableButtons: false,
            completedSubassignments: [] as number[],
            isCompletingAssignment: false,
            IconSource,
        };
    },
    computed: {
        computedState: {
            get(): MultiStepFormState & Form<MultiStepFormState> {
                return this.$props.state;
            },
            set(newValue: MultiStepFormState) {
                this.$emit('update:state', newValue);
            },
        },
        computedAssignment: {
            get(): Assignment | null {
                return this.$props.assignment;
            },
            set(newValue: Assignment) {
                this.$emit('update:assignment', newValue);
            },
        },
        inputDetails(): ({ type: 'error'; text: string } | undefined)[][] {
            const formHasErrors = this.computedState.errors.hasErrors();
            return (this.computedState.form as FormType).sections.map((formSection: FormSection) =>
                formSection.children.map((formElement) => {
                    const error = formHasErrors ? this.computedState.errors.all().find((currentError) => currentError.id === `${formSection.id}.${formElement.id}`) : undefined;
                    return error ? { type: 'error', text: error.value } : undefined;
                })
            );
        },
        dynamicSectionOptions(): {
            [key: string]: null | {
                deleteSectionButtonLabel: string | undefined;
                prototypeId: string | undefined;
            };
        } {
            return this.computedState.form?.sections.reduce((accum: any, section) => {
                const matches = section.id.match(/(.*)_(\d*)$/);
                if (!(matches && matches.length === 3)) {
                    accum[section.id] = null;
                    return accum;
                }

                const dynamicSectionPrototype: FormSection | undefined = this.computedState.form?.sections.find((currentSection) => currentSection?.id === matches[1]);

                let buttonThatAddedSection: undefined | FormElement;
                let sectionIndex = 0;
                while (!buttonThatAddedSection && sectionIndex < (this.computedState.form?.sections.length || 0)) {
                    buttonThatAddedSection = this.computedState.form?.sections[sectionIndex].children.find(
                        (currentElement) => currentElement.type === 'button' && currentElement.type_settings.action === 'clone-section' && currentElement.type_settings.reference === dynamicSectionPrototype?.id
                    );
                    sectionIndex++;
                }
                const deleteSectionButtonLabel = buttonThatAddedSection?.type_settings.values.find((currentValue) => currentValue.id === 'btn-delete-name').value;

                if (buttonThatAddedSection) {
                    accum[section.id] = {
                        deleteSectionButtonLabel: deleteSectionButtonLabel || '',
                        prototypeId: dynamicSectionPrototype?.id,
                    };
                } else {
                    accum[section.id] = null;
                }
                return accum;
            }, {});
        },
        formType() {
            if (this.computedState.assignment_id !== null && this.computedState.assignment_id !== undefined) {
                return 'ASSIGNMENT';
            }

            if (this.computedState.assignment_template_id !== null && this.computedState.assignment_template_id !== undefined) {
                return 'ASSIGNMENT_TEMPLATE';
            }

            return 'RECORD';
        },
        deleteButtonText() {
            if (this.formType === 'ASSIGNMENT') {
                return i18n.global.t('global.forms.steps.deleteAssignment.deleteRecordButtonLabel');
            }

            if (this.formType === 'ASSIGNMENT_TEMPLATE') {
                return i18n.global.t('global.forms.steps.deleteAssignmentTemplate.deleteRecordButtonLabel');
            }

            return i18n.global.t('global.forms.steps.deleteRecord.deleteRecordButtonLabel');
        },
        subassignments() {
            if (!this.computedAssignment?.sub_assignments?.length) {
                return null;
            }

            const filteredAssignments = this.computedAssignment.sub_assignments.filter((subassignment) => subassignment.is_completed !== true);

            return filteredAssignments.length ? filteredAssignments : null;
        },
    },
    created() {
        this.computedState.form?.sections.forEach((currentSection) => {
            const dynamicallyAddedSectionMatches = currentSection.id.match(/(.*)_(\d*)$/);
            if (dynamicallyAddedSectionMatches && dynamicallyAddedSectionMatches.length === 3) {
                this.dynamicallyAddedSections[dynamicallyAddedSectionMatches[1]] = this.dynamicallyAddedSections[dynamicallyAddedSectionMatches[1]]
                    ? Math.max(this.dynamicallyAddedSections[dynamicallyAddedSectionMatches[1]], Number(dynamicallyAddedSectionMatches[2]) + 1)
                    : Number(dynamicallyAddedSectionMatches[2]) + 1;
            } else {
                this.dynamicallyAddedSections[currentSection.id] = 0;
            }

            this.collapsibleSections[currentSection.id] = currentSection.collapsed;
        });
    },
    mounted() {
        this.validateForm();
    },
    methods: {
        findChildById(sections: FormSection[], id: string) {
            if (!sections) {
                return null;
            }

            for (const section of sections) {
                for (const child of section.children) {
                    if (child.id === id) {
                        return child;
                    }
                }
            }

            return null;
        },
        async onBackendRetrieval(triggeredElement: FormElement, triggeredElementParentSection: FormSection) {
            triggeredElement.disabled = true;

            const preconditionElements = triggeredElement.type_settings.preconditions?.map((currentPrecondition) => {
                // Preconditions can be sent in the section.element format. If not, we assume that the elements will be within the same section as the triggered element
                const preconditionConstituents = currentPrecondition.split('.');
                const section =
                    preconditionConstituents.length > 1 ? this.computedState.form?.sections.find((currentSection) => currentSection.id === preconditionConstituents[0]) : triggeredElementParentSection;
                return { section, element: section?.children.find((currentElement) => currentElement.id === preconditionConstituents[preconditionConstituents.length - 1]) };
            });

            if (!preconditionElements) {
                return;
            }

            const responseData: { values: { id: string; value: string | { id: string; value: string }[]; values?: any[] }[]; messages: { id: string; value: string }[] } = (await store
                .dispatch(ActionType.GetDynamicFormData, {
                    form: {
                        route: triggeredElement.type_settings.route!,
                        data: {
                            context: this.computedState.form?.context,
                            values: preconditionElements.map((currentPreconditionElement) => ({
                                id: `${currentPreconditionElement?.section?.id}.${currentPreconditionElement?.element?.id}`,
                                value: currentPreconditionElement?.element?.value,
                            })),
                        },
                    },
                })
                .catch((error) => {
                    triggeredElement.disabled = false;
                })) as { values: { id: string; value: string }[]; messages: { id: string; value: string }[] };

            responseData.values.forEach((currentResponseValue) => {
                const currentResponseElementIdConstituents = currentResponseValue.id.split('.');
                const section =
                    currentResponseElementIdConstituents.length > 1
                        ? this.computedState.form?.sections.find((currentSection) => currentSection.id === currentResponseElementIdConstituents[0])
                        : triggeredElementParentSection;
                const element = section?.children.find((currentElement) => currentElement.id === currentResponseElementIdConstituents[currentResponseElementIdConstituents.length - 1]);

                if (!element) {
                    return;
                }

                element.value = currentResponseValue.value;

                const isKeyValuePair = element.type_settings?.input_type === 'keyvaluepair';
                const isKeyValuePairList = element.type_settings?.input_type === 'keyvaluepairlist';

                if (!isKeyValuePair && !isKeyValuePairList) {
                    return;
                }

                if (Array.isArray(currentResponseValue.values)) {
                    element.type_settings.values = currentResponseValue.values;

                    if (Array.isArray(currentResponseValue.value)) {
                        const filteredValues = (currentResponseValue.value as { id: string; value: string }[]).filter((currentValue) => currentResponseValue.values?.some((v) => v.id === currentValue.id));

                        element.value = filteredValues;
                    } else if (!currentResponseValue.values.some((v) => v.id === element.value?.id)) {
                        element.value = null;
                    }
                } else {
                    const initialChild = this.findChildById(this.initialFormState!.sections, element.id);
                    const initialValues = initialChild?.type_settings?.values;

                    if (initialValues?.length) {
                        element.type_settings.values = initialValues;

                        if (Array.isArray(currentResponseValue.value)) {
                            const filteredValues = (currentResponseValue.value as { id: string; value: string }[]).filter((currentValue) => initialValues?.some((v) => v.id === currentValue.id));

                            element.value = filteredValues;
                        } else if (!initialValues.some((v) => v.id === element.value?.id)) {
                            element.value = null;
                        }
                    }
                }
            });

            this.computedState.setErrors(responseData.messages);
            this.$nextTick(() => {
                this.validateForm();
            });

            triggeredElement.disabled = false;
        },
        onDeleteSection(formSection: FormSection) {
            const sectionIndex = this.computedState.form!.sections.findIndex((currentSection) => currentSection.id === formSection.id);
            const prototypeId = this.dynamicSectionOptions[formSection.id]?.prototypeId;
            if (sectionIndex < 0 || !prototypeId) {
                return;
            }
            this.dynamicallyAddedSections[prototypeId]--;
            this.computedState.form!.sections.splice(sectionIndex, 1);
            this.$nextTick(() => {
                this.validateForm();
            });
        },
        onCloneSection(cloneFormSectionOptions: CloneFormSectionOptions) {
            const sectionIndex = this.initialFormState!.sections.findIndex((currentSection) => currentSection.id === cloneFormSectionOptions.sectionReference);
            if (sectionIndex < 0) {
                return;
            }
            const clonedSection = cloneDeep(this.initialFormState!.sections[sectionIndex]);
            this.dynamicallyAddedSections[clonedSection.id] = this.dynamicallyAddedSections[clonedSection.id] === undefined ? 1 : this.dynamicallyAddedSections[clonedSection.id] + 1;
            clonedSection.id = `${clonedSection.id}_${this.dynamicallyAddedSections[clonedSection.id] - 1}`;

            clonedSection.collapsed = clonedSection.copy_collapsed;
            this.collapsibleSections[clonedSection.id] = true;

            clonedSection.children.forEach((currentElement) => {
                currentElement.disabled = currentElement.type_settings.copy_locked ? currentElement.disabled : false;
            });
            this.computedState.form!.sections.splice(sectionIndex + this.dynamicallyAddedSections[this.computedState.form!.sections[sectionIndex].id], 0, clonedSection);
            this.$nextTick(() => {
                this.validateForm();
            });
        },
        onNextButtonClicked() {
            if (this.validForm && this.config.editable !== false && !this.disableButtons) {
                this.disableButtons = true;
                this.$emit('submit');
            }
        },
        getRules: dynamicFormRules,
        getPreconditionDisabled(formElement: FormElement, formSection: FormSection): boolean {
            return Boolean(
                formElement.type_settings.preconditions &&
                    formSection.children.find(
                        (currentFormElement) =>
                            !this.getRules(currentFormElement).every((rule) => rule.validate(currentFormElement.value)) && (formElement.type_settings.preconditions || []).includes(currentFormElement.id)
                    )
            );
        },
        getPreconditionData(formElement: FormElement, formSection: FormSection) {
            return formElement.type_settings.preconditions?.map((currentPrecondition) => {
                const preconditionElementReference = formSection.children.find((currentFormElement) => {
                    const currentPreconditionConstituents = currentPrecondition.split('.');
                    return currentFormElement.id === currentPreconditionConstituents[currentPreconditionConstituents.length - 1];
                });

                return { label: preconditionElementReference?.label, value: preconditionElementReference?.value, id: preconditionElementReference?.id };
            });
        },
        onToggleExpandSectionButtonClicked(formSection: FormSection) {
            formSection.collapsed = !formSection.collapsed;
        },
        validateForm() {
            if (this.validationDebounceTimeout) {
                clearTimeout(this.validationDebounceTimeout);
            }

            this.validationDebounceTimeout = setTimeout(() => {
                const validationResult = Object.values(this.$refs)
                    .filter((currentRef: any) => currentRef)
                    .every((currentRef: any) => currentRef[0].validInput);
                this.validForm = validationResult;
            }, 150);
        },
        async completeAssignment() {
            if (!this.computedAssignment) {
                return;
            }

            try {
                if (this.isCompletingAssignment) return;

                this.isCompletingAssignment = true;

                await store.dispatch(ActionType.EditAssignment, { form: { assignmentId: this.computedAssignment.id, payload: { ...this.computedAssignment, is_completed: true } } });

                this.$emit('submission');
                this.$emit('force-close');
            } catch (err) {
                this.isCompletingAssignment = false;
            }
        },
        async completeSubassignment(subassignmentId: number) {
            if (!this.computedAssignment?.sub_assignments) {
                return;
            }

            try {
                if (this.completedSubassignments.includes(subassignmentId)) return;

                this.completedSubassignments.push(subassignmentId);

                await store.dispatch(ActionType.EditSubassignment, { form: { assignmentId: this.computedAssignment.id, subId: subassignmentId, payload: { is_completed: true } } });

                const index = this.computedAssignment.sub_assignments.findIndex((s) => s.id === subassignmentId);

                // Fake delay for better transition
                await new Promise((resolve) => setTimeout(resolve, 200));

                if (index !== -1) {
                    (this.computedAssignment as Assignment).sub_assignments[index].is_completed = true;

                    if (this.computedAssignment.sub_assignments.every((s) => s.is_completed)) {
                        this.$emit('submission');
                        this.$emit('force-close');
                    }
                }
            } catch (err) {
                this.completedSubassignments = this.completedSubassignments.filter((s) => s !== subassignmentId);
            }
        },
    },
});
</script>
