<template>
    <div>
        <Modal close-esc-event="keydown" :close-button="false" modal-class="m-auto border-0" mask-class="grid" @closed="onModalClosed">
            <template #toggle="scope">
                <button class="bg-primary-800 border-primary-700 border rounded-xl py-2 px-4 text-white cursor-pointer" @click="scope.open">
                    <span class="w-full flex space-x-2 content-center">
                        <span class="grid content-center h-7 min-w-4 justify-center my-auto">
                            <Icon class="my-auto" style="width: 17px; height: 17px" :src="IconSource.RefreshActivitiesLight"></Icon>
                        </span>
                        <span class="update-key-figures-button-text text-lg my-auto">
                            {{ $t('layouts.dashboard.topBar.updateKeyFigures.buttonLabel') }}
                        </span>
                    </span>
                </button>
            </template>

            <template #header="scope">
                <CloseModalButton @close="scope.close"></CloseModalButton>
            </template>

            <template #body="scope">
                <div class="bg-white w-full relative rounded-lg grid py-6 px-10 m-auto space-y-4 max-w-128">
                    <h2 class="text-xl font-semibold text-center">{{ $t('layouts.dashboard.topBar.updateKeyFigures.modal.header') }}</h2>
                    <p class="text-center">
                        {{ $t('layouts.dashboard.topBar.updateKeyFigures.modal.message') }}
                    </p>
                    <div class="flex justify-center">
                        <div class="min-w-40">
                            <CustomButton :disabled="disableButtons" @click="onConfirmClicked(scope.close)">
                                {{ $t('layouts.dashboard.topBar.updateKeyFigures.modal.confirmUpdateButtonLabel') }}
                            </CustomButton>
                        </div>
                    </div>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Modal } from '@makeabledk/vue-ui/components/modal';
import CloseModalButton from '@/components/common/CloseModalButton.vue';
import CustomButton from '@/components/ui/CustomButton.vue';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';

export default defineComponent({
    components: { CustomButton, Modal, CloseModalButton },
    data() {
        return { disableButtons: false };
    },
    methods: {
        async onConfirmClicked(close: CallableFunction) {
            this.disableButtons = true;
            await store.dispatch(ActionType.UpdateKeyFigures);
            close();
            window.location.reload();
        },
        onModalClosed() {
            this.disableButtons = false;
        },
    },
});
</script>

<style scoped>
.update-key-figures-button-text {
    display: none;
}
@media (min-width: 1080px) {
    .update-key-figures-button-text {
        display: inline;
    }
}
</style>
